import React from 'react';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router';

import './features/Main/index.module.css';
import Main from './features/Main/index';
import DocumentList from './features/DocumentList';
import { store } from './store';

const App: React.FC = () => {
  return (
    <ReduxStoreProvider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/doclist" element={<DocumentList />} />
        </Routes>
      </BrowserRouter>
    </ReduxStoreProvider>
  );
};

export default App;
