import React, { useContext, useEffect, useState } from 'react';

import { DataContext } from '../features/Main';
import { secondsToClock } from '../utils/time';

export const CurrentTrack: React.FC = () => {
  const { refetchGuildState, api, guildState, timeDiff } =
    useContext(DataContext);

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number | null>(null);

  const currentTrack = api.useQuery(
    'get',
    '/v1/track/{trackId}',
    {
      params: {
        path: {
          trackId: guildState?.currentTrack || '',
        },
      },
    },
    { enabled: !!guildState?.currentTrack },
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (guildState?.playbackStartTimestamp !== undefined) {
        setStartTime(
          new Date(
            Number(
              guildState?.playbackStartTimestamp?.toString().split('.')[0] || 0,
            ) + timeDiff,
          ),
        );
      } else {
        setStartTime(null);
        setElapsedTime(null);
      }

      if (!startTime) return;

      setElapsedTime(Date.now() - startTime.getTime());
    }, 250);
    return () => clearInterval(interval);
  }, [startTime, guildState]);

  return (
    <div className="flex flex-row items-center p-4">
      <h2 className="m-2 mb-2 text-xl font-bold">
        {currentTrack.data?.data?.name || "Nothing's playing"}
      </h2>
      {(() => {
        if (elapsedTime === null) return null;

        if (elapsedTime / 1000 >= (currentTrack.data?.data?.duration || 0)) {
          refetchGuildState();
        }

        return (
          <h2 className="m-2 mb-2 text-xl font-bold">
            {`${secondsToClock(Math.floor(elapsedTime / 1000))}/${secondsToClock(currentTrack.data?.data?.duration || 0)}`}
          </h2>
        );
      })()}
    </div>
  );
};
