// import { Link } from 'react-router';
import createFetchClient from 'openapi-fetch';
import createClient from 'openapi-react-query';
import React, { createContext, useEffect, useState } from 'react';

import { CurrentTrack } from '../../components/CurrentTrack';
import { PlaybackControls } from '../../components/PlaybackControls';
import { QueueList } from '../../components/QueueList';
import { TrackList } from '../../components/TrackList';
import type { paths } from '../../lib/api/v1';
import CONSTANTS from '../../utils/constants';

import styles from './index.module.css';
import FileUpload from '../../components/FileUpload';

export const fetchClient = createFetchClient<paths>({
  baseUrl: CONSTANTS.API_URL,
  credentials: 'include',
});
export const api = createClient(fetchClient);

type ElementType<T> = T extends (infer U)[] ? U : never;

export const DataContext = createContext<{
  tracks:
    | paths['/v1/track/list']['get']['responses']['200']['content']['application/json']['data']
    | undefined;
  getTrackById: (
    trackId?: string,
  ) =>
    | ElementType<
        paths['/v1/track/list']['get']['responses']['200']['content']['application/json']['data']
      >
    | undefined;
  guildState:
    | paths['/v1/guild/status/{guildId}']['get']['responses']['200']['content']['application/json']['data']
    | undefined;
  refetchGuildState: () => void;
  fetchClient: typeof fetchClient;
  api: typeof api;
  timeDiff: number;
  user:
    | paths['/v1/user/me']['get']['responses']['200']['content']['application/json']
    | undefined;
}>({
  tracks: undefined,
  getTrackById: () => undefined,
  guildState: undefined,
  refetchGuildState: async () => undefined,
  fetchClient,
  api,
  timeDiff: 0,
  user: undefined,
});

const Main: React.FC = () => {
  const timeQuery = api.useQuery('get', '/v1/time');

  const [timeDiff, setTimeDiff] = useState(0);
  useEffect(() => {
    if (
      typeof timeQuery.data === 'string' &&
      !isNaN(Date.parse(timeQuery.data))
    ) {
      setTimeDiff(Date.now() - Date.parse(timeQuery.data));
    }
  }, [timeQuery.data]);

  const { data, error, isLoading, fetchStatus, isPending } = api.useQuery(
    'get',
    '/v1/user/me',
  );
  const tracksQuery = api.useQuery('get', '/v1/track/list');

  const guildQuery = api.useQuery(
    'get',
    '/v1/guild/status/{guildId}',
    {
      params: {
        path: {
          guildId: data?.voiceGuild || '',
        },
      },
    },
    {
      enabled: !!data && !!data?.voiceGuild, // Only run this query if "data" is available
    },
  );

  if (isLoading || !data) return fetchStatus.toString();

  if (error) return `An error occured: ${error}`;
  if (data.discordId === undefined && !isPending) {
    return (
      <main className={styles.container}>
        <header
          className={styles.header}
          style={{
            flexDirection: 'row',
          }}
        >
          <h1 className="pr-4">{data.username}</h1>
          <button
            className={styles.button}
            onClick={() => {
              window.open(`${CONSTANTS.API_URL}/v1/auth/discord`);
            }}
          >
            LOG IN
          </button>
        </header>
      </main>
    );
  }

  return (
    <main className={styles.container}>
      <header
        className={styles.header}
        style={{
          flexDirection: 'row',
        }}
      >
        <h1 className="pr-4">{data.username}</h1>
        <button
          className={styles.button}
          onClick={() => {
            console.log('logging out');
            fetchClient.GET('/v1/auth/logout');
          }}
        >
          LOG OUT
        </button>
      </header>
      <div className="flex flex-col items-center p-4">
        <DataContext.Provider
          value={{
            tracks: tracksQuery.data?.data,
            getTrackById: (trackId?: string) => {
              if (!trackId) return;
              return tracksQuery.data?.data?.find(
                (track) => track?.trackId === trackId,
              );
            },
            guildState: guildQuery.data?.data,
            refetchGuildState: async () => guildQuery.refetch(),
            fetchClient,
            api,
            timeDiff,
            user: data,
          }}
        >
          <CurrentTrack />
          <PlaybackControls />
          <div className="mt-4 flex flex-row items-start">
            <QueueList />
            <TrackList />
          </div>
        </DataContext.Provider>
      </div>
    </main>
  );
};
export default Main;
