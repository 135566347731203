import React, { useContext } from 'react';

import { api, fetchClient, DataContext } from '../features/Main';
import styles from '../features/Main/index.module.css';

export const QueueList: React.FC = () => {
  const queueQuery = api.useQuery('get', '/v1/playback/queue');
  const { getTrackById } = useContext(DataContext);

  return (
    <div className="mt-4 w-full">
      <div className="justify-left flex items-start space-x-4">
        <h3 className="text-lg font-bold">Queue</h3>
        <button
          className="rounded bg-red-500 px-2 py-1 text-black hover:bg-red-600"
          onClick={() => {
            fetchClient.POST('/v1/playback/queue/clear').then(() => {
              queueQuery.refetch();
            });
          }}
        >
          Clear
        </button>
      </div>
      <ul className="mt-2 space-y-2">
        {queueQuery.data?.data?.map(
          (track: { adderDiscordId?: string; trackId?: string }, index) => (
            <li
              key={index}
              className="flex items-center justify-between rounded bg-gray-100 p-2 shadow"
            >
              <span className="text-black">
                {getTrackById(track?.trackId)?.name || 'unknown'}
              </span>
              <button
                className="rounded bg-red-500 px-2 py-1 text-black hover:bg-red-600"
                onClick={() => {
                  // fetchClient
                  //   .POST(`/v1/queue/remove/{trackId}`, {
                  //     params: {
                  //       path: {
                  //         trackId: track.trackId,
                  //       },
                  //     },
                  //   })
                  //   .then(() => {
                  //     queueQuery.refetch();
                  //   });
                }}
              >
                Remove
              </button>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};
