import { get as levenshteinDistance } from 'fast-levenshtein';
import * as fuzz from 'fuzzball';
import React, { useContext, useState } from 'react';

import { fetchClient, DataContext } from '../features/Main';
import FileUpload from './FileUpload';

export const TrackList: React.FC = () => {
  const { tracks } = useContext(DataContext);

  const [filteredTracks, setFilteredTracks] = useState<
    { trackId: string; name: string }[] | undefined
  >(undefined);

  if (tracks === undefined) return <div>Loading...</div>;

  return (
    <div className="mt-4 w-full">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold">Track List</h3>
        <FileUpload />
        <input
          type="text"
          placeholder="Search tracks..."
          className="rounded border border-gray-300 px-2 py-1 text-black"
          onChange={(e) => {
            const normalizeString = (str: string) => {
              return str
                .replace(/[\W_]+/g, ' ')
                .trim()
                .toLowerCase();
            };

            const searchTerm = e.target.value.toLowerCase();
            setFilteredTracks(
              tracks?.filter((track) => {
                const trackName = track.name?.toLowerCase() || '';
                return (
                  levenshteinDistance(
                    normalizeString(trackName),
                    normalizeString(searchTerm),
                  ) < 3 ||
                  normalizeString(trackName).includes(searchTerm) ||
                  trackName.includes(searchTerm) ||
                  fuzz.ratio(
                    normalizeString(trackName),
                    normalizeString(searchTerm),
                  ) > 60
                );
              }) as any,
            );
          }}
        />
      </div>
      <ul className="mt-2 space-y-2 pt-1">
        {(filteredTracks || tracks)?.map((track, index) => (
          <li
            key={index}
            className="mx-auto flex w-full max-w-md items-center justify-between rounded bg-gray-100 p-2 shadow"
            style={{ flex: '0 0 auto' }}
          >
            <span className="truncate text-black">{track.name}</span>
            <button
              className="rounded bg-blue-500 px-2 py-1 text-black hover:bg-blue-600"
              onClick={() => {
                fetchClient
                  .POST(`/v1/playback/play/{trackId}`, {
                    params: {
                      path: {
                        trackId: track.trackId || 'n/a',
                      },
                    },
                  })
                  .then(() => {
                    return fetchClient.POST('/v1/playback/resume');
                  });
              }}
            >
              Play
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
