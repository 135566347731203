import React from 'react';
import ReactDOM from 'react-dom/client';
import CONSTANTS from './utils/constants';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const queryClient = new QueryClient();

console.log(CONSTANTS.API_URL);

if (process.env.NODE_ENV === 'development') {
  // Prepare MSW in a Service Worker
  import('../mocks/browser')
    .then(({ worker }) => {
      worker.start();
    })
    // Launched mock server, and then start client React app
    .then(() =>
      root.render(
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>,
      ),
    );
} else {
  // Production
  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>,
  );
}
