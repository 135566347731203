import React, { useContext } from 'react';

import { DataContext } from '../features/Main';

export const PlaybackControls: React.FC = () => {
  const { refetchGuildState, fetchClient, guildState } =
    useContext(DataContext);

  return (
    <div className="relative flex">
      <div className="flex space-x-4">
        <button
          className="rounded bg-gray-300 px-4 py-2 font-bold text-black hover:bg-gray-400"
          onClick={() => {
            fetchClient.POST('/v1/playback/pause').then(refetchGuildState);
          }}
          data-tooltip="Pause"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          className="rounded bg-gray-300 px-4 py-2 font-bold text-black hover:bg-gray-400"
          onClick={() => {
            fetchClient.POST('/v1/playback/resume').then(refetchGuildState);
          }}
          data-tooltip="Resume"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          className="rounded bg-gray-300 px-4 py-2 font-bold text-black hover:bg-gray-400"
          onClick={() => {
            fetchClient.POST('/v1/playback/skip').then(refetchGuildState);
          }}
          data-tooltip="Skip"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path d="M5.055 7.06C3.805 6.347 2.25 7.25 2.25 8.69v8.122c0 1.44 1.555 2.343 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.343 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256l-7.108-4.061C13.555 6.346 12 7.249 12 8.689v2.34L5.055 7.061Z" />
          </svg>
        </button>
      </div>

      <div className="absolute top-2 left-full ml-4">
        {(() => {
          let color = guildState?.loopMode === 'none' ? '#666' : 'white';

          return (
            <div data-tooltip={'Looping ' + (guildState?.loopMode || '?')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                className="size-6"
                // cursor="pointer"
                onClick={() => {
                  const mode =
                    guildState?.loopMode === 'none'
                      ? 'all'
                      : guildState?.loopMode === 'all'
                        ? 'one'
                        : 'none';
                  fetchClient
                    .POST('/v1/playback/loop/{mode}', {
                      params: {
                        path: {
                          mode,
                        },
                      },
                    })
                    .then(refetchGuildState);
                }}
              >
                <path
                  d="M18 4L21 7M21 7L18 10M21 7H7C4.79086 7 3 8.79086 3 11M6 20L3 17M3 17L6 14M3 17H17C19.2091 17 21 15.2091 21 13"
                  stroke={color}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {guildState?.loopMode !== 'one' ? (
                ''
              ) : (
                <span className="pointer-events-none absolute bottom-2.5 left-3 flex h-3.5 w-3.5 items-center justify-center rounded-sm bg-white text-xs text-black select-none">
                  <strong>1</strong>
                </span>
              )}
            </div>
          );
        })()}
      </div>
    </div>
  );
};
