import React, { useContext, useState } from 'react';
import { DataContext } from '../features/Main';
import { paths } from '../lib/api/v1';

const FileUpload: React.FC = () => {
  const { fetchClient } = useContext(DataContext);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<
    | undefined
    | paths['/v1/track/upload']['post']['responses']['200']['content']['application/json']['data']
    | string
  >(undefined);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      fetchClient
        .POST('/v1/track/upload', {
          body: formData as any,
        })
        .then((resp) => {
          if (resp.data?.status !== 'success')
            setUploadedFile(resp.error?.toString());
          setUploadedFile(resp?.data?.data);
        });
      console.log('Uploading file:', selectedFile.name);
    } else {
      console.log('No file selected');
    }
  };

  return (
    <div>
      <>
        <button
          onClick={() => setShowModal(true)}
          className="rounded bg-blue-500 px-2 py-1 text-black hover:bg-blue-600"
        >
          Upload
        </button>
        {showModal && (
          <div className="bg-opacity-50 fixed inset-0 flex items-center justify-center bg-black">
            <div className="rounded bg-white p-4 text-black shadow-lg">
              <h2 className="mb-2 text-lg font-bold">Upload File</h2>
              <input
                type="file"
                onChange={handleFileChange}
                className="mb-2 text-black file:mr-4 file:rounded file:border-0 file:bg-gray-200 file:px-4 file:py-2 file:text-black hover:file:bg-gray-300"
              />
              <div className="flex justify-end">
                <button
                  onClick={() => setShowModal(false)}
                  className="mr-2 rounded bg-gray-300 px-2 py-1 hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleUpload();
                    setShowModal(false);
                  }}
                  className="rounded bg-blue-500 px-2 py-1 text-black hover:bg-blue-600"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        )}
        {!!uploadedFile && (
          <div className="bg-opacity-50 fixed inset-0 flex items-center justify-center bg-black">
            <div className="rounded bg-white p-4 text-black shadow-lg">
              <h2 className="mb-2 text-lg font-bold">Upload File Result</h2>
              <div className="flex justify-center">
                {typeof uploadedFile === 'string' ? (
                  uploadedFile
                ) : (
                  <>
                    <ul>
                      {Object.entries(uploadedFile).map(([key, value]) => (
                        <li key={key}>
                          <strong>{key}:</strong> {value?.toString()}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => setUploadedFile(undefined)}
                  className="mr-2 rounded bg-gray-300 px-2 py-1 hover:bg-gray-400"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default FileUpload;
